.app {
  background-color: #fff;
}

.rainbow-box {
  /*background-color: #302244;*/
  border: 5px solid transparent;
  border-image: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%);
  border-image-slice: 1;
  padding: 1em;
}

.parameters, .example {
  padding: 1em;
}

.inputfield {
  width: 100% !important;
}

@media print
{    
    .example *, 
    .parameters *
    {
        display: none !important;
    }

    .app { 
      display: block;
      margin: 2em; 
    }
}

@media screen
{    
  .example *, 
  .parameters *
  {
      display: block;
  }

  .app { 
    display: none !important;
    margin: 2em;
   }
}

.small-footer {
  margin-top: 0.2em;
  margin-bottom: 0em;
  font-size: 6pt;
}

.row {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.title, .subtitle, .small-footer { 
  color: #000;
  text-align: center;
  font-family: Verdana;
}

.title {
  font-size: 10pt;
  font-weight: bold;
}

.subtitle {
  font-size: 8pt;
  font-weight: normal;
}
